@font-face {
    font-family: "Inter";
    src: url("../../src/fonts/Inter.otf");
}

html, body, #app, #root {
    height: 100%;
    margin: 0;
}

body {
    /*font-family: 'promixa-nova', sans-serif;*/
    font-family: "Inter var",sans-serif;
    color: black;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-size: 0.9em;
}

#mainContent {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
}

#mainHeader {
    margin-top: 10%;
    margin-bottom: 3em;
}

header {
    position: relative;
    text-align: center;
}

header nav{
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
}

.contactLinks {
    align-items: center;
    justify-content: center;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

h1 {
    color: #222;
    margin: 15px 0;
}

h1, h2, h3, h4 {
    font: normal normal normal 2.5em/1.5em 'Inter';
    font-weight: 500;
}

h3.subHeader {
    font-size: 1.5em;
    line-height: 1.3em;
}

h3 {
    color: #222;
    padding-bottom: .25em;
    margin: 15px 0;
}

#name {
    top: -3em;
}

#icons {
    padding-bottom: .45em;
}

.img-icons {
    -webkit-transition: opacity 0.6s ease-in-out;
    -moz-transition: opacity 0.6s ease-in-out;
    -o-transition: opacity 0.6s ease-in-out;
    transition: opacity 0.6s ease-in-out;
}
.img-icons:hover {
    opacity: 0.4;
    filter: alpha(opacity=40);
}

.move-icons {
    -webkit-transition: transform 0.2s ease;
    -moz-transition: transform 0.2s ease;
    -o-transition: transform 0.2s ease;
    transition: transform 0.2s ease;
}

.img-icons:hover {
    transform: translate(0,-5px);
    -webkit-transform: translate(0,-5px);
    -o-transform: translate(0,-5px);
    -moz-transform: translate(0,-5px);
}

#locationIcon {
    height: 20px;
    vertical-align: middle;
}

.skills-list {
    height: 20px;
}

.skills-icons-tips {
    padding-right: 20px;
}

.skills-icons {
    height: 20px;
    margin: 0 auto;
}

.skills-text {
    margin-top: 10px;
}

.skills-icons {
}

.repos-list {
    margin-right: -8px;
    margin-left: -8px;
    flex-wrap: wrap !important;
    padding-left: 0;
}

.d-flex {
    display: flex !important;
}

.project-card {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    font-weight: 500;
    border: 1px solid #d1d5da;
    /*background-color: #fff;*/
    background-color: #fafafa;
    font-size: 14px;
    line-height: 1.5;
    color: #24292e;
    width: 100%;
    margin-bottom: 16px !important;
    flex-direction: column;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 3px;
    padding-right: 8px !important;
    padding-left: 8px !important;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.06),0 1px 1px 0 rgba(0,0,0,0.04),0 1px 3px 0 rgba(0,0,0,0.04);
}

.repo-extra {
    padding: 14px !important;
    bottom:0;
    margin-top: auto;
}

.repo-item {
    display: flex;
    flex-direction: column;
    padding: 14px 14px 0 14px;
}

.repo-item>a {
    color: #0366d6;
    text-decoration: none;
}

.text-link>a:hover{
    text-decoration: underline;
}
.text-link>a {
    color: #0366d6;
    text-decoration: none;
}

.repo-item>.name {
    word-wrap: break-word;
}
.repo-item>.description {
    margin-top: 8px;
    word-wrap: break-word;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px !important;
    flex: 1 0 auto;
}

.text-grey {
    color: #586069 !important;
}

.repo-item>.language {
    font-size: 12px !important;
    margin-bottom: 0 !important;
    margin-top: 0;
}
.repo-item>.updated {
    font-size: 11px !important;
    margin-bottom: 0 !important;
    margin-top: 0;
}


/*Responsive*/
/*@media (min-width: 576px){*/
    /*#mainContent {*/
        /*width: 540px;*/
    /*}*/
/*}*/
@media (max-width: 767px){
    header nav{
        position: static;
    }
    h2 {
        display: none;
    }
}
@media (min-width: 768px){
    /*#mainContent {*/
        /*width: 720px;*/
    /*}*/
    .project-card {
        width: 47%;
        max-width: 456px;
    }
}
@media (min-width: 992px){
    /*#mainContent {*/
        /*width: 970px;*/
    /*}*/

    /*.project-card {*/
        /*width: 30%;*/
    /*}*/
}
@media (min-width: 1200px){
    #mainContent {
        max-width: 1140px;
    }
}
/*@media (min-width: 1440px){*/
    /*#mainContent {*/
        /*width: 1400px;*/
    /*}*/
/*}*/



#footer {
    text-align: center;
    font-size: 0.7em;
    color: #999;
    text-decoration: none;
    bottom:0;
}

#footer a:visited {
    color: #999;
}